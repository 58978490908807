/* ------------------------------
    Estilos para ETIQUETAS HTML
------------------------------ */

a.ayuda {
  cursor: help;
}

body {
  color: #363642 !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

button {
  border: 1px solid;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  padding: 4px 6px !important;
  transition: background 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

button:hover {
  transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.15s ease-in-out;
}

button a,
button a:hover {
  color: inherit;
  text-decoration: none;
}

h2 {
  background-color: #fff;
  color: #4D4B75;
  font-size: 22px;
  font-weight: 700;
  padding-right: 1em;
}

p {
  line-height: 1;
}

p a {
  border-bottom: 1px solid;
  color: inherit;
  text-decoration: none;
}

p a:hover {
  color: #D4007F;
  text-decoration: none;
}
.margen-100-auto {
  margin: 100px auto;
}
select {
  border-width: 2px;
  border-color: #BF6DA0 !important;
  background-image: linear-gradient(45deg,transparent 50%,#fff 50%),
                    linear-gradient(135deg,#fff 50%,transparent 50%),
                    linear-gradient(to right,#BF6DA0,#BF6DA0);
  background-position: calc(100% - 20px) calc(1em + 2px),
                       calc(100% - 15px) calc(1em + 2px),100% 0;
  background-size: 5px 5px,5px 5px,2.5em 2.5em;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  padding: 0 2.75em 0 .5em!important;
}

select:hover,
select:focus {
  background-image: linear-gradient(45deg,transparent 50%,#fff 50%),
                    linear-gradient(135deg,#fff 50%,transparent 50%),
                    linear-gradient(to right,#D4007F,#D4007F);
  border-color: #D4007F !important;
  box-shadow: 0 0 2px 3px rgba(191,109,160, 0.4) !important;
  transition: all 0.2s ease-in-out;
}

/* table tr:first-of-type td {
  height: 360px;
} */

table {
  line-height: 1;
  margin-top: 3em;
}

tbody td {
  text-align: center;
}

td {
  padding: 0 10px;
}

td:nth-of-type(2),
td:nth-of-type(3),
td:nth-of-type(4) {
  width: 80%;
}

thead tr.candidatos td {
  font-size: 18px;
  padding: 0 10px;
  text-align: center;
  vertical-align: top;
}

thead tr:first-of-type td {
  border-bottom: 1px solid;
  border-right: 1px dashed #eab5df;
  height: 404px;
  /* overflow: hidden; */
  vertical-align: bottom;
}

thead tr:first-of-type td:first-of-type {
  border: none;
  /* min-width: 220px; */
  overflow: inherit;
  padding: 0;
}

thead tr:first-of-type td:nth-of-type(2) {
  border-left: 1px solid;
}

tr.candidatos p {
  font-size: 1.05em;
  margin-bottom: 0;
}

::selection {
  background-color: #f9dbed;
}

/* ------------------------------
    Estilos para SELECTORES ID
------------------------------ */



/* ------------------------------
    Estilos para CLASES CSS
------------------------------ */

.etiqueta1 {
  text-transform: capitalize;
}

.GlosarioNav a{
  background-color: #4D4B75;
  color: white;
  display: inline-block;
  padding: .5rem 1rem;
  transition: background-color 0.2s ease-in-out;
  border: 0px transparent;
}

.GlosarioNav a:hover {
  text-decoration: none;
  background-color: #4D4B75;
  transition: background-color 0.25s ease-in-out;
  color: white;
}

.ayudaNav {
  text-align: right;
}

.ayudaNav a {
  background-color: #BF6DA0;
  color: #fff !important;
  display: inline-block;
  padding: .5rem 1rem;
  transition: background-color 0.2s ease-in-out;
}

.ayudaNav a:hover {
  text-decoration: none;
  background-color: #D4007F;
  transition: background-color 0.25s ease-in-out;
}

.barra {
  background-color: #BF6DA0;
  min-width: 80px;
  width: auto;
}

.barra.conPorcentaje:after {
  background-color: #4d4b75;
  border-radius: 4px;
  color: white;
  content: attr(data-value) "%";
  font-size: 14px;
  left: calc(33% - 30px);
  position: relative;
  padding: 4px 6px;
  top: -33px;
}

.barra.conPorcentaje:before {
  border-bottom: 12px solid transparent;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #4d4b75;
  content: "";
  height: 0;
  left: calc(25% + 20px);
  position: relative;
  top: 1px;
  width: 0px;
}

.barrasCasRep {
  margin: 30px 0;
}

.barrasFunc {
  align-items: center;
  border-left: 1px solid #B2B2B2;
  display: flex;
  padding: 16px 15px 16px 0;
}

.barrasFunc .progress {
  width: 100%;
}

.barraProgress {
  margin-top: 6px;
  padding-right: 10px;
  width: 25%;
}

.barraProgressCasInst {
  margin-top: 6px;
  padding-right: 10px;
  padding-left: 0px;
  width: 25%;
}

.barraProgress+div {
  width: 75%;
}

.bigIcon {
  color: #4D4B75;
  font-size: 3.2em;
  margin-left: 20px;
}

.bordeComponentes {
  border: 1px solid #ccc;
}

.bordeLateral {
  border-left: 1px solid #B2B2B2;
}

.botonTransparente {
  border-color: #BF6DA0;
  background: rgba(0,0,0,0);
  color:  #BF6DA0 !important;
  float: right;
}
  
.botonTransparente:hover {
  border-color: #D4007F;
  background: #D4007F;
  color:  #ffffff !important;
  float: right;
}

.botonTransparenteResumenGral {
  border-color: #BF6DA0;
  background: rgba(0,0,0,0);
  color:  #BF6DA0 !important;
  float: none;
}
  
.botonTransparenteResumenGral:hover {
  border-color: #D4007F;
  background: #D4007F;
  color:  #ffffff !important;
  float: none;
}

.botonMorado {
  border-color: #4D4B75;
  background: #4D4B75;
  color:  #ffffff !important;
  float: right;
}

.botonMorado:hover {
  border-color: #D4007F;
  background: #D4007F;
  color:  #ffffff;
  float: right;
}

.caeInst img {
  float: left;
  margin-bottom: 22px;
  margin-right: 2em;
}

.cargandoCont {
  height: 300px;
  padding: 40px 0;
  width: 100%;
}

.cargando {
  margin: 0 auto;
  text-align: center;
  width: 160px;
}

.circulo {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background:#BF6DA0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin:0px auto;
  padding:3%;  
}

.comparaIncidentes div[role="progressbar"]:first-of-type {
  background-color: #BF6DA0;
}

.comparaIncidentes div[role="progressbar"]:last-of-type {
  background-color: #ccc;
}

.comparaIncidentes p:first-of-type::before {
  border-bottom: 0.5em solid transparent;
  border-left: 0.7em solid #BF6DA0;
  border-top: 0.35em solid transparent;
  content: "";
  display: inline-block;
}

.comparaIncidentes p:last-of-type::after {
  border-bottom: 0.35em solid transparent;
  border-right: 0.7em solid #ccc;
  border-top: 0.35em solid transparent;
  content: "";
  display: inline-block;
}

.contenido {
  height: 100%;
  min-width: 100%;
  padding: 1.2em;
}

.contenedorGraficas {
  padding-top: 1px;
}

.contenido.borde, .borde-gris {
  border: 1px solid #b2b2b2;
}

.borde-gris {
  border: 1px solid #b2b2b2;
  padding: 7px;
}

.contornoDiv {
  padding: 20px;
}

.contRepr {
  height: 220px;
}

.datosIncidente .enfasis1 {
  margin-bottom: 0.5em;
}

.datosIncidente .enfasis1 a {
  border-bottom: 3px solid;
}

.datosInf {
  background-color: #fff;
  float: right;
  padding: 20px 10px 10px 20px;
  text-align: right;
}

.datosInf p {
  font-size: 18px;
  line-height: 1em;
  margin-bottom: 0;
  font-weight: bold;
}

#intervaloCorte {
  font-size: 15px;
  line-height: 2em;
  margin-bottom: 0;
  font-weight: lighter;
}

#intervaloCorte span {
  font-size: 15px;
  line-height: 2em;
  margin-bottom: 0;
  font-weight: bold;
  color: #BF6DA0;
}

.datosInf a {
  color: #BF6DA0;
  border-bottom: 1px solid;
}

.datosInf a:hover {
  color: #D4007F;
  text-decoration: none;
}

.datosInfCont {
  height: 65px;
}

.dimensionContainerGricasPie{
  max-height: 100%;
  max-width: 100%;
}

.disabledbutton {
  cursor: default;
  pointer-events: none;
  opacity: 0.4;
}

.disabledbutton .datosIncidente .enfasis1 a {
  border-bottom: none;
}

.download {
  margin-right: 10px;
}

.dropdown-item.active {
  background-color: rgba(255,255,255,0);
  color: #4D4B75;
  padding: .35rem 1.5rem;
}

.dropdown-item.active:hover {
  background-color: #B9B9D6;
}

.dropdown-menu {
  background-color: #DADAEF;
  border: none !important;
  border-radius: 0 !important;
  margin: 0;
  padding: 0;
  width: 100%;
}

.dropdown.nav-item .nav-link:not(.active) {
  color: #FFFFFF;
  background-color: transparent;
  transition: background-color 0.25s ease-in-out;
}

.dropdown.nav-item .nav-link.notactive {
  color: #FFFFFF;
  background-color: transparent;
  transition: background-color 0.25s ease-in-out;
}

.dropdown.nav-item .nav-link.active {
  background-color: #E8EDEF;
  color: #4D4B75;
  transition: background-color 0.25s ease-in-out;
}

.dropdown.nav-item:not(.show) .nav-link:hover {
  background-color: #99458D;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif !important;
  transition: background-color 0.25s ease-in-out;
}

.dropdown.nav-item.show .nav-link {
  background-color: #99458D;
  /* color: #4D4B75 !important; */
  transition: background-color 0.25s ease-in-out;
}

.dropdown.nav-item.show .nav-link.active {
  color: #fff;
}

.dropdown-toggle::after {
  transform: rotate(0deg);
  transition: transform 0.25s ease-in-out;
}

.dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(180deg);
  transition: transform 0.25s ease-in-out;
}

.encabezado {
  padding: 20px 0;
}

.enfasis1,
.enfasis2 {
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
}

.enfasis1 {
  font-size: 30px;
}

.enfasis2 {
  font-size: 24px;
  margin-right: 5px;
}

.linea-izq::before, .linea-izq-sr::before {
  content: "";
  border-left: 1px solid rgba(0,0,0,.1);
  position: absolute;
  height: 50%;
  top: 25%;
  left: -25px;
}

.linea-izq-sr::before {
  position: absolute;
  height: 100%;
  top: -25%;
  left: -10px;
}

.entidadMasMenos {
  background-position: center;
  background-repeat: no-repeat;
  float: left;
  min-height: 30px;
  height: 100%;
  width: 40px;
}

.entidadMasMenos.AGS { background-image: url(../images/entidades/01_ags.png); }
.entidadMasMenos.BC { background-image: url(../images/entidades/02_bc.png); }
.entidadMasMenos.BCS { background-image: url(../images/entidades/03_bcs.png); }
.entidadMasMenos.CAMP { background-image: url(../images/entidades/04_camp.png); }
.entidadMasMenos.COAH { background-image: url(../images/entidades/05_coah.png); }
.entidadMasMenos.COL { background-image: url(../images/entidades/06_col.png); }
.entidadMasMenos.CHIS { background-image: url(../images/entidades/07_chis.png); }
.entidadMasMenos.CHIH { background-image: url(../images/entidades/08_chih.png); }
.entidadMasMenos.CDMX { background-image: url(../images/entidades/09_cdmx.png); }
.entidadMasMenos.DGO { background-image: url(../images/entidades/10_dgo.png); }
.entidadMasMenos.GTO { background-image: url(../images/entidades/11_gto.png); }
.entidadMasMenos.GRO { background-image: url(../images/entidades/12_gro.png); }
.entidadMasMenos.HGO { background-image: url(../images/entidades/13_hgo.png); }
.entidadMasMenos.JAL { background-image: url(../images/entidades/14_jal.png); }
.entidadMasMenos.MEX { background-image: url(../images/entidades/15_mex.png); }
.entidadMasMenos.MICH { background-image: url(../images/entidades/16_mich.png); }
.entidadMasMenos.MOR { background-image: url(../images/entidades/17_mor.png); }
.entidadMasMenos.NAY { background-image: url(../images/entidades/18_nay.png); }
.entidadMasMenos.NL { background-image: url(../images/entidades/19_nl.png); }
.entidadMasMenos.OAX { background-image: url(../images/entidades/20_oax.png); }
.entidadMasMenos.PUE { background-image: url(../images/entidades/21_pue.png); }
.entidadMasMenos.QRO { background-image: url(../images/entidades/22_qro.png); }
.entidadMasMenos.QROO { background-image: url(../images/entidades/23_qroo.png); }
.entidadMasMenos.SLP { background-image: url(../images/entidades/24_slp.png); }
.entidadMasMenos.SIN { background-image: url(../images/entidades/25_sin.png); }
.entidadMasMenos.SON { background-image: url(../images/entidades/26_son.png); }
.entidadMasMenos.TAB { background-image: url(../images/entidades/27_tab.png); }
.entidadMasMenos.TAMPS { background-image: url(../images/entidades/28_tamps.png); }
.entidadMasMenos.TLAX { background-image: url(../images/entidades/29_tlax.png); }
.entidadMasMenos.VER { background-image: url(../images/entidades/30_ver.png); }
.entidadMasMenos.YUC { background-image: url(../images/entidades/31_yuc.png); }
.entidadMasMenos.ZAC { background-image: url(../images/entidades/32_zac.png); }


/* .row div.col-6:nth-child(2) .entidadMasMenos {
  background: linear-gradient(#B2B2B2, #B2B2B2);
  background-size: 1px 100%;
  background-position: 0%;
  background-repeat: no-repeat;
} */

.entidadMasMenos+div {
  float: left;
  width: calc(100% - 40px);
}

/* .entidadMasMenos+div p:first-of-type strong {
  color: #4D4B75;
} */

.entidadMasMenos+div strong+p {
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.entidadMasMenos+div strong:last-child {
  color: initial;
  margin-right: 5px;
}

.footer2 {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
}

.footer {
  font-size: 13px;
  text-align: center;
  width: 100%;
}

.footer p:first-of-type,
.footer p:last-of-type {
  margin-bottom: 0;
}
  
.footerFondoUno {
  background: #ECECEC;
  padding: 8px 0px;
}

.footerFondoDos {
  color: #FFFFFF;
  background: #363642;
  padding: 12px 0px;
}

.footerFondoDos strong {
  font-weight: 500;
}

.footerFondoVersion {
  color: #363642;
  margin-left: 10px;
}

.funcionariosCont {
  margin: 20px 0;
  padding: 0 18px;
}

.funcionariosCont .row {
  margin-left: 0;
  margin-right: 0;
  text-align: center;
}

.graficasCasInst .enfasis1 {
  margin-right: 16px;
}

.graficasCasInst .enfasis2 {
  float: left;
  margin-right: 5px;
}

.graficasCasInst .row {
  margin: 30px 0;
}

.graficaPie {
  float: left;
  height: 90px;
  width: 90px;
}

.graficaPie+div {
  float: left;
  padding-top: 12px;
  width: calc(100% - 90px)
}

.highcharts-container {
  margin: 0 auto;
  padding: 0;
}

.highcharts-legend .highcharts-point {
  stroke: #B2B2B2;
}

.highcharts-null-point {
  cursor: default;
  fill: #f6f6f6 !important;
  fill-opacity: 1;
  stroke: #b2b2b2;
}

.highcharts-series path:not(.highcharts-null-point):hover {
  stroke: #363642;
  stroke-width: 1.5;
}

.home {
  padding-top: 10px;
}

.iconosPurple {
  color: #4D4B75;
  font-size: 2em;
}

.iconoReloj {
  font-size: 20px !important;
  color: #BF6DA0;
  margin-right: 8px;
}

.imagenUno {
  margin-right: 30px;
}

.imagenUno,
.imagenUno+div {
  float: left;
  text-align: left;
}

.imagenUno+div h1 {
  margin-bottom: 0.15em;
}

.incidentesIndividuales {
  border: 1px solid #BF6DA0;
  border-radius: 50%;
  color: #4D4B75;
  display: inline-block;
  font-weight: bold;
  line-height: 3.5;
  margin-bottom: 20px;
  margin-right: 20px;
  min-height: 56px;
  min-width: 56px;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.incidentesIndividuales:hover {
  background-color: #D4007F;
  border: 1px solid #D4007F;
  color: #fff;
}

.incidentesMasItem {
  float: left;
  font-size: 18px;
  padding: 10px;
  text-align: center;
  width: 20%;
}

.repreInformacionMasItem {
  float: left;
  font-size: 18px;
  padding: 10px;
  text-align: center;
  width: auto;
}

.incidentesMasItem:not(:first-of-type) .etapa p:before {
  content: "Durante ";
}

.incidentesMasItem:hover {
  background-color: #ffe6f7;
  cursor: pointer;
}

.incidentesMasItem:hover .circulo {
  background: #D4007F;
}

.incidentesMasItem:hover .enfasis1 {
  color: #D4007F;
}

.incidentesMasItem .etapa {
  min-height: 60px;
}

.info {
  color: #BF6DA0;
  cursor: pointer;
  font-size: 20px!important;
  margin: 0 8px;
  text-decoration: none;
}

.infoPad {
  padding-left: 60px;
}

.info:hover {
  color: #D4007F;
}
  
.lineaMedia {
  background: linear-gradient(#B2B2B2, #B2B2B2);
  background-size: 100% 1px;
  background-position: 0 50%;
  background-repeat: no-repeat;
}

.lineaVertical {
  background: linear-gradient(#B2B2B2, #B2B2B2);
  background-size: 1px 100%;
  background-position: 50% 0;
  background-repeat: no-repeat;
  margin-bottom: 1em;
}

.lineReportadas {
  margin-right: 1rem;
  border-top: 1px solid #B2B2B2 !important;
}

/* .loading {
  margin-top: 10em;
  text-align: center;
  color: gray;
} */
      
.logoINE {
    height: 36px;
    margin-top: 12px;
}

.logoSIJE{
    height: 52px;
}

.masMenosSub {
  color: #4D4B75;
  font-weight: bolder;
}

.menuResponsivo {
  display: none;
}

.modalIncidentes small {
  font-size: 15px;
  width: 100%;
}
.texto-centrado {
  text-align: center;
}
.modalResponsivo button,
.modalResponsivo button:hover {
  background-color: rgba(0,0,0,0);
  border: none;
  color: #ffe6f7;
  font-size: 28px;
  margin-bottom: 12px;
}

.modalResponsivo .accordion {
  width: 100%;
}

.modalResponsivo .modal-dialog {
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0;
}

.modalResponsivo .modal-content {
  background-color: #4D4B75 ;
  height: 100%;
  padding: 12px;
  width: 100%;
}

.nameFunc {
  float: left;
  margin-right: 10px;
  width: 90px;
}

.navMenu {
  background-color: #4D4B75;
  font-size: 16px;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500;
}

.navMenu ul li{
  list-style-type: none;
  float: left;
  clear: none;
  max-width: 300px;
  padding: 8px 10px;
}

.navMenu .fa {
  color: #fff;
}

.navMenu .inicio {
  color: #fff;
  line-height: 1.9;
  padding: 0.3em 0.6em;
}

.navMenu .inicio:hover {
  background-color: #99458D;
}

.ocultarDesktop {
  display: none;
}

.preludio {
  font-size: 24px;
}

.preludio2 {
  font-size: 18px;
}

.preludio2-centrado {
  text-align: center;
  font-size: 16px;
}
.progress {
  background-color: #ececec;
  border-radius: 0;
}

.progress-bar {
  background-color: #BF6DA0;
}

.pullRight{
  float: right !important;
}

.question{
  margin-right: 10px;
}

.quitarSubrayado {
  border-bottom: 0px solid;
}

.reordenar .porciento {
  float: left;
  margin-right: 16px;
  width: 120px;
}

.reordenarCasInst {
  width: 60%;
}

.reordenar .porciento+div {
  float: left;
  width: calc(100% - 136px);
}

.repGral .enfasis1 {
  color: #BF6DA0;
}

.repGral .row.lineaMedia,
.repProp .row.lineaMedia,
.repSup .row.lineaMedia {
  margin: 0;
}

.repProp .circulo {
  background: #4D4B75;
}

.repProp .enfasis1 {
  color: #4D4B75;
}

.repSup .circulo {
  background: #9876ce;
}

.repSup .enfasis1 {
  color: #9876ce;
}

.reportesPorTipo h5 {
  border-bottom: 1px solid #B2B2B2;
  color: #4D4B75;
  padding: 0.6em 0;
}

.resumenMorado {
  color: #4D4B75
}

.resumenMorado::before {
  content: " - ";
}

.resumenMorado,
.resumenRosa{
  font-size: 28px;
  font-weight: 700;
}

.resumenRosa{
  color: #BF6DA0
}

.sinInfoIcon {
  color: #9d9dbf;
  font-size: 5em;
}

.subEncabezado {
  display: inline;
}
    
.subTituloSitio {
  font-size: 18px;
  font-weight: 500;
}

.text-left-pie {
  text-align: left;
  padding-left: 30px;
  padding-top: 30px !important;
}

.textoColorBlanco {
  color: white;
}

.textoColorRojo{
  color: red;
}

.textoColorVerde {
  color: green !important;
}

.tituloSitio {
  font-size: 24px;
  font-weight: bold;
}

.valorY {
  background-image: linear-gradient(gray, gray);
  background-position: 100% 0%;
  background-repeat: no-repeat;
  background-size: 10px 1px;
  font-size: 12px;
  height: 40px;
  line-height: 0;
  padding-right: 15px;
  text-align: right;
  width: 70px;
}

.valorY:nth-child(odd):after {
  background: #fdf7f9;
  background-repeat: no-repeat;
  content: "";
  height: 40px;
  left: 106px;
  position: absolute;
  width: calc(100% - 143px);
  z-index: -1;
}

.vinculo {
  border-bottom: 1px solid #bf6da0;
  color: #BF6DA0;
}

.vinculo:hover {
  border-bottom: 1px solid #D4007F;
  color: #D4007F;
  text-decoration: none;
}

.descriptionEjeY {
  position: absolute;
  writing-mode: vertical-lr;
  margin-left: -14px;
  margin-top: -100px;
  top: 0;
  bottom: 0;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  transform: rotate(-180deg);
}

.contenidoGraphBarras {
  margin: 35px 35px 0px 0px;
  position: relative;
}

.pieDeBarra {
  height: 65px;
}

.encabezadoGraficasBarra {
  margin: 0px -10px 0px -10px;
  text-align: center;
}

.btns-enlaces-resumen-gral {
  text-align: center;
}

.title-res-gral-casillas {
  height: 60px;
}

.graph-pie-res-gral {
  height: 100px;
}

.casillas-heigth-res-gral {
  height: 120px;
}

.margen-btns-casillas-res-gral {
  margin-top: 30px;
}

/* ------------------------------
    M e d i a   Q U E R Y ' S
------------------------------ */

@media (max-width: 1199px) and (min-width: 992px) {

  tbody {
    font-size: 12px;
  }

  .barra {
    min-width: 60px;
  }
  
  .barra.conPorcentaje:before {
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #4d4b75;
    left: calc(25% + 9px);
  }

  .barra.conPorcentaje:after {
    font-size: 10px;
    padding: 4px;
    top: -28px;
  }

  .valorY {
    background-size: 5px 1px;
    font-size: 10px;
    padding-right: 5px;
    width: 40px;
  }

  .valorY:nth-child(odd):after{
    left: 76px;
    width: calc(100% - 112px);
  }

  .descriptionEjeY {
    position: absolute;
    writing-mode: vertical-lr;
    font-family: 'Roboto', sans-serif;
  }

  .encabezadoGraficasBarra {
    text-align: center;
  }
}

.tooltip-casillas-instaldas {
  color: #fff;
  background-color: #f00;
}

@media (max-width: 992px) {
  .resumenMorado,
  .resumenRosa {
    font-size: 22px;
  }
}
@media (max-width: 991px) {

  thead tr:first-of-type td {
    height: 288px;
  }

  .ayudaNav a {
    font-size: 13px;
  }

  .datosInf p {
    font-size: 13px;
  }

  .dropdown-item.active {
    font-size: 13px;
  }

  .dropdown.nav-item .nav-link {
    font-size: 13px;
    padding: 0.6em 0.8em;
  }

  .incidentesMasItem .etapa {
    min-height: 80px;
  }

  .navMenu .inicio {
    line-height: 1.6;
  }

  .preludio {
    font-size: 22px;
  }

  .question {
    margin-right: 8px;
  }

  .subTituloSitio {
    font-size: 16px;
  }

  .tituloSitio {
    font-size: 16px;
  }

  .valorY {
    height: 32px;
  }

  .valorY:nth-child(odd):after {
    height: 32px;
  }

  .descriptionEjeY {
    position: absolute;
    writing-mode: vertical-lr;
    font-family: 'Roboto', sans-serif;
  }

}
@media (max-width: 768px) {
  .linea-izq::before, .linea-izq-sr::before {
    display: none;
  }
}
@media (max-width: 767px) {

  button {
    font-size: 14px;
  }

  thead tr:first-of-type td {
    height: 225px;
  }

  .barra {
    min-width: 74px;
  }

  .barra.conPorcentaje:after {
    font-size: 12px;
  }

  .barraProgress {
    margin-top: 0;
  }

  .barraProgress,
  .barraProgress+div {
    width: 100%;
  }

  .datosIncidente,
  .incidentesMasItem .etapa {
    float: left;
    text-align: left;
    width: 50%;
  }

  .datosIncidente p:first-of-type {
    display: inline-block;
  }

  .datosInf {
    float: left;
    font-size: 11px;
    padding: 0;
    text-align: left;
  }

  .datosInfCont.lineaMedia {
    background: none;
    height: 30px;
  }

  .footer {
    margin-bottom: 40px;
  }

  .funcionariosCont .row {
    text-align: left !important;
  }

  .graficasCasInst .row {
    display: flex;
  }

  .graficasCasInst .row .reordenar:nth-child(1) {
    order: 2;
  }

  .graficasCasInst .row .reordenar:nth-child(2) {
    order: 1;
  }

  .incidentesMasItem {
    font-size: 16px;
    border-bottom: 1px solid #B2B2B2;
    padding: 1em;
    width: 100%;
  }

  .incidentesMasItem:last-of-type {
    border-bottom: none;
  }

  .incidentesMasItem .etapa {
    min-height: 10px;
    padding-right: 1em;
    padding-top: 12px;
  }

  .repreInformacionMasItem{
    font-size: 16px;
    border-bottom: 1px solid #B2B2B2;
    padding: 1em;
    width: 100%;
  }

  .logoINE {
    margin-top: 0;
  }

  .menuResponsivo {
    background-color: #4D4B75;
    bottom: 0;
    display: block;
    left: 0;
    /* height: 40px; */
    position: fixed;
    width: 100%;
  }

  .menuResponsivo a {
    color: #fff;
    display: block;
    font-size: 16px;
    padding: 10px;
  }

  .modalResponsivo .card {
    background-color: rgba(0,0,0,0);
    border: none;
  }

  .modalResponsivo .card-body {
    padding: 10px 20px;
  }

  .modalResponsivo .card-body,
  .modalResponsivo .nav-link {
    color: #fff;
    width: 100%;
  }

  .modalResponsivo .card-header {
    background-color: rgba(0,0,0,0);
    border-bottom: 1px solid #61659a;
    color: #ffe6f7;
    cursor: default;
    font-size: 18px;
    width: 100%;
  }

  .modalResponsivo .card-header::after {
    content: "";
    border-bottom: 0;
    border-left: .3em solid transparent;
    border-right: .3em solid transparent;
    border-top: .3em solid;
    float: right;
    margin-left: .255em;
    vertical-align: .255em;
  }

  .modalResponsivo .nav-link::before {
    color: #eab5df;
    content: "\2022";
    margin-right: 8px;
  }

  .nameFunc {
    display: block;
    width: 100%;
  }

  .nameFunc p {
    margin-bottom: 4px;
  }

  .nameFunc+div p {
    display: inline-block;
  }

  .nameFunc+div p:last-child {
    margin-left: 10px;
  }

  .ocultarDesktop {
    display: initial;
  }

  .ocultarMovil {
    display: none;
  }

  .resumenMorado,
  .resumenRosa {
    font-size: 24px;
  }

  .row div.bordeLateral:nth-of-type(2) {
    border-left: none;
  }

  .subTituloSitio {
    font-size: 12px;
    font-weight: 300;
  }

  .valorY {
    height: 25px;
  }

  .valorY:nth-child(odd):after {
    height: 25px;
  }

  .descriptionEjeY {
    position: absolute;
    writing-mode: vertical-lr;
    font-family: 'Roboto', sans-serif;
  }

}

@media (max-width: 575px) {

  body {
    font-size: 14px;
  }

  h2 {
    font-size: 16px;
  }

  table {
    font-size: 0.9em;
  }

  td {
    padding: 0 8px;
  }

  td:nth-of-type(2),
  td:nth-of-type(3),
  td:nth-of-type(4) {
    width: 23%;
  }

  .barra {
    min-width: 50px;
  }

  .barra.conPorcentaje:after {
    font-size: 10px;
    left: calc(33% - 14px);
    padding: 3px 4px;
    top: -22px;
  }

  .barra.conPorcentaje::before {
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #4d4b75;
    /* left: calc(25% + 24px); */
    top: 2px;
  }

  .enfasis1 {
    font-size: 26px;
  }

  .enfasis2 {
    font-size: 20px;
  }

  .incidentesIndividuales {
    line-height: 3.8;
    margin-right: 16px;
  }

  .preludio {
    font-size: 18px;
  }

  .resumenMorado,
  .resumenRosa {
    font-size: 18px;
  }

  .valorY {
    background-size: 6px 1px;
    font-size: 9px;
    padding-right: 6px;
    width: 42px;
  }

  .valorY:nth-child(odd):after {
    left: 77px;
    width: calc(100% - 113px);
  }

  .descriptionEjeY {
    position: absolute;
    writing-mode: vertical-lr;
    font-size: 0.9em;
    font-family: 'Roboto', sans-serif;
  }


}

@media (max-width: 560px) {
  .graficasCasInst .enfasis2 {
    width: 100%;
  }
}

@media (max-width: 552px) {
  .imagenUno {
    margin-right: 0;
    width: 78px;
  }

  .imagenUno+div {
    width: calc(100% - 78px);
  }

  .pieDeBarra {
    height: 100px;
  }

  .encabezadoGraficasBarra {
    text-align: center;
  }
}

@media (max-width: 406px) {

  .ajusteMiniGrafica {
    padding-left: 8px;
    padding-right: 8px;
  }

  .ajusteMiniGrafica .contenido {
    padding: 1.2em 0.7em;
  }

  .valorY:nth-child(odd):after {
    left: 62px;
    width: calc(100% - 82px);
  }
}

@media (max-width: 378px) {
  .ajusteMiniGrafica {
    padding-left: 0px;
    padding-right: 0px;
  }

}

.representantesInfoDistribucion{
  display:         flex;
  flex-wrap:       wrap;
  justify-content: center;
}

.representantesPPCI-emblema {
  height: 60px;
}

/* ------------------------------
    Estilos para Mapas
------------------------------ */
.error-mapa {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
/* ------------------------------
    Estilos para el GLOSARIO
------------------------------ */
.tituloPagina {
  margin-bottom: 35px;
}

.numerales h3,
.numerales p {
  background-color: #cd0475;
  border-radius: 50%;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  height: 40px;
  line-height: 1.8;
  margin-right: 1.2em;
  text-align: center;
  width: 40px;
}

.termino {
  margin-left: 2em;
  padding: 1.2em 0;
}

.bordes {
  border-bottom: 1px solid #c2c2c2;
  padding: 4em 0;
}

.navbar {
  padding: 0;
}

.nav-link {
  background-color: rgba(0,0,0,0);
  border: none;
  color: rgb(0, 0, 0);
  font-size: 16px;
}

#letras-nav {
  position: sticky;
  top: 0;
  z-index: 9999;
}

#letras-nav li a {
  background-color: #fff;
  border: none;
  color: #333;
  font-weight: 500;
}

#letras-nav li a.active {
  background-color: #fff;
  color: #333;
}

#letras-nav li a:hover {
  background-color: #f2f2f2;
  color: #cd0475;
}

#letras-nav li:before {
  content: none;
}

.btnUlises {
  border: 0px;
  
}

.required::before {
  color: red;
  content: "*";
  margin-right: 8px;
}